.loaderAnimation {
  animation: loaderAnimation 1s infinite;
}

@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
